<template>
  <div class="wai">
    <div class="login-container">
      <el-form
        :model="formData"
        class="login-form"
        :rules="dataRule"
        ref="formData"
      >
        <!-- <div class="title-container" style="text-align: left">
          <h3 style="margin-left: 10px">用户登录</h3>
        </div> -->
        <el-form-item prop="username" style="background: #294a87">
          <i
            class="el-icon-user-solid"
            style="
              color: #fff;
              font-size: 18px;
              margin-left: 16px;
              background: #294a87;
            "
          ></i>
          <el-input
            v-model="formData.username"
            placeholder="用户名"
            style="background: #294a87"
          ></el-input>
        </el-form-item>
        <el-form-item prop="password" style="background: #294a87">
          <i
            class="el-icon-lock"
            style="
              color: #fff;
              font-size: 18px;
              margin-left: 16px;
              background: #294a87;
            "
          ></i>
          <el-input
            v-model="formData.password"
            type="password"
            placeholder="密码"
            style="background: #294a87"
          ></el-input>
        </el-form-item>
        <el-button type="primary" class="login-button" @click="handleLogin"
          >登录</el-button
        >
      </el-form>
    </div>
    <div
      style="
        height: 30px;
        margin: 0 auto;
        width: 900px;
        color: #fff;
        margin-top: 90px;
        font-size: 22px;
      "
    >
      <a href="https://beian.miit.gov.cn/" target="_blank"
        >jsfgx.online -@村花有礼（广西）农产品开发有限责任公司
        桂ICP备2022009123号-1</a
      >
    </div>
  </div>
</template>

<script>
import { login } from "../../api/api";
import { Message } from "element-ui";
import router from "../../router";
export default {
  data() {
    return {
      formData: {
        username: "",
        password: "",
      },
      dataRule: {
        username: [
          {
            required: true,
            message: "请输入用户名",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    // 登录
    handleLogin() {
      this.$refs["formData"].validate((valid) => {
        if (valid) {
          this.dataListLoading = true;

          login(this.formData).then((data) => {
            if (data.code === 0) {
              // 初始化面包屑
              let selectMenuList = [
                {
                  path: "/",
                  name: "home",
                  label: "首页",
                },
              ];
              localStorage.setItem(
                "selectMenuList",
                JSON.stringify(selectMenuList)
              );
              // 设置权限菜单项
              let menus = [];

              for (let menu of data.data.menus) {
                let name = menu.url.substring(1);
                menus.push({
                  path: menu.url,
                  name: name,
                  label: menu.name,
                  icon: menu.icon,
                });
              }
              localStorage.setItem("menus", JSON.stringify(menus));
              // 登录成功，存储用户信息（含token），跳转
              localStorage.setItem("userInfo", JSON.stringify(data.data));
              Message({
                message: "登录成功",
                type: "success",
              });
              router.push("/home");
            } else {
              // 登录失败
              Message.error("密码错误，登录失败");
            }
          });

          this.dataListLoading = false;
        } else {
          Message.error("请输入信息");
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
body {
  padding: 0;
  margin: 0;
}
.wai {
  background: url("../../public/loginbg.png");
  background-size: 100% 100%;
  background-position: center center;
  overflow: auto;
  height: 100%;
  width: 100%;
}
.login-container {
  margin-left: 38.6%;
  margin-top: 26%;
  overflow: hidden;
  width: 22.8%;
  color: #fff;

  .login-form {
    position: relative;
    width: 100%;
    max-width: 100%;
    padding: 10px 7%;
    margin: 0 auto;
    overflow: hidden;

    ::v-deep .el-form-item {
      border: 1px solid rgba(255, 255, 255, 0.1);
      background: rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      color: #454545;
      width: 85%;
    }

    ::v-deep .el-input {
      display: inline-block;
      height: 47px;
      width: 85%;

      input {
        background: transparent;
        border: 0px;
        -webkit-appearance: none;
        border-radius: 0px;
        padding: 12px 5px 12px 15px;
        color: #eee;
        height: 47px;
        caret-color: #fff;
      }
    }
    .login-button {
      width: 85%;
      box-sizing: border-box;
    }
  }

  .tips {
    font-size: 16px;
    line-height: 28px;
    color: #fff;
    margin-bottom: 10px;

    span {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }

  .svg-container {
    padding: 6px 5px 6px 15px;
    color: #889aa4;
    vertical-align: middle;
    display: inline-block;
  }

  .title-container {
    position: relative;

    .title {
      font-size: 26px;
      color: #eee;
      margin: 0px auto 40px auto;
      text-align: center;
      font-weight: bold;
    }

    ::v-deep .lang-select {
      position: absolute;
      top: 4px;
      right: 0;
      background-color: white;
      font-size: 22px;
      padding: 4px;
      border-radius: 4px;
      cursor: pointer;
    }
  }

  .show-pwd {
    // position: absolute;
    // right: 10px;
    // top: 7px;
    font-size: 16px;
    color: #889aa4;
    cursor: pointer;
    user-select: none;
  }
}
</style>
